import TYPES from '@/types';
import Vue from 'vue';

// Application
import OnBoardingSearchEmailsQuery
  from '@/modules/on-boarding/emails/application/queries/on-boarding-search-emails-query';

// Domain
import { EmailEntity } from '@/modules/on-boarding/emails/domain/entities/email-entity';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class SupportWidgetSuccessViewModel {
  @Inject(TYPES.ON_BOARDING_SEARCH_EMAILS_QUERY)
  readonly onBoardingSearchEmailsQuery!: OnBoardingSearchEmailsQuery;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  private readonly view!: Vue;

  public constructor(view: Vue) {
    this.view = view;
  }

  i18n_namespace = 'components.support-widget.support_widget_success';

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  email = '';

  get emails() {
    return this.onBoardingSearchEmailsQuery.execute();
  }

  setDefaultEmail(emails: Array<EmailEntity>) {
    const emailDefault = emails.find(
      (email) => email.customer_email_default,
    ) || { email: { email_address: '' } };

    this.email = emailDefault.email.email_address;
  }

  endProcess = () => {
    this.view.$emit('endProcess');
  }
}
