

































import { Component, PropSync, Vue } from 'vue-property-decorator';

import SupportWidgetSuccessViewModel
  from '@/vue-app/view-models/components/support-widget/support-widget-success-view-model';

@Component({ name: 'SupportWidgetAnonymousSuccess' })
export default class SupportWidgetAnonymousSuccess extends Vue {
  @PropSync('emailUser', { type: String, required: true })
  email!: string;

  support_success_view_model = Vue.observable(
    new SupportWidgetSuccessViewModel(this),
  );
}
